import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "login_form" }
const _hoisted_2 = {
  key: 0,
  class: "err-msg"
}

import { computed, ref, watch } from "vue";
import { isPasswordStrong } from "@/utils/index";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { firebaseService } from "@/utils/firebaseService";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginComponent',
  setup(__props) {

const password = ref("");
const passwordError = ref("");
const router = useRouter();
const store = useStore();
const email = ref<string>("");

const canLogin = computed(() => email.value && password.value && !passwordError.value);

// 实时获取密码
watch(password, (newPassword) => {
  console.log("Checking password:", newPassword);
  if (!isPasswordStrong(newPassword)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
});

// 检查密码强度
const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
};

// 登录成功跳转home页
const navigateToHome = async () => {
  if (!passwordError.value && email.value && password.value) {
    // console.log('Username:', name.value);
    const result = await firebaseService.login(email.value, password.value);
    if (result.success) {
      store.commit("setUser", { username: email.value, email: email.value });
      router.push("/");
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_password = _resolveComponent("a-input-password")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "login_form-title" }, "LOGIN", -1)),
      _createVNode(_component_a_input, {
        value: email.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
        placeholder: "Account",
        class: "account"
      }, null, 8, ["value"]),
      _createVNode(_component_a_input_password, {
        value: password.value,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
        onFocusout: checkPassword,
        placeholder: "Password",
        class: "password"
      }, null, 8, ["value"]),
      (passwordError.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(passwordError.value), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["login_wrap", {
          login_button_start: !canLogin.value,
          login_button_end: canLogin.value,
        }]),
        onClick: navigateToHome
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("span", { class: "login_button-text" }, "LOGIN", -1)
      ]), 2),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "login_footer" }, [
        _createElementVNode("span", { class: "login_forgot-password" }, "Forgot password."),
        _createElementVNode("a", {
          class: "login_sign-in",
          href: "/register",
          target: "_blank",
          rel: "noopener noreferrer"
        }, " Sign in here ")
      ], -1))
    ])
  ]))
}
}

})