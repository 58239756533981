import config from "@/consts/config";
import localstorageKey from '@/consts/localstorageKey';

/**
 * 查询是否可以触发
 * @returns 返回是否可以触发、以及离下一次可以触发的时间是多少
 */
export function canTriggerEmail(): { canTrigger: boolean; remainingTime: number } {
  const localStorageKey = localstorageKey.LOGIN_COUNT_DOWN_KEY;
  const { cooldownPeriod } = config; // 60 seconds in milliseconds
  const currentTime = Date.now();

  // 从 localStorage 获取上次触发的时间
  const lastTriggerTime = localStorage.getItem(localStorageKey);
  if (lastTriggerTime) {
    const elapsedTime = currentTime - parseInt(lastTriggerTime, 10);

    if (elapsedTime < cooldownPeriod) {
      const remainingTime = Math.ceil((cooldownPeriod - elapsedTime) / 1000);
      return { canTrigger: false, remainingTime };
    }
  }

  return { canTrigger: true, remainingTime: 0 };
}

/**
 * 当用户触发时记录新的时间
 */
export function recordEmailTrigger(): void {
  const localStorageKey = localstorageKey.LOGIN_COUNT_DOWN_KEY;
  const currentTime = Date.now();

  // 更新 localStorage
  localStorage.setItem(localStorageKey, currentTime.toString());
}

/**
 * 判断邮箱是否合法
 * @param email 校验邮箱
 * @returns 是否为合法邮箱
 */
export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

/**
 * 判断密码强弱度是否足够: 至少 8 位、字母/数字/字符 三选二
 */
export function isPasswordStrong(password: string): boolean {
  if (password.length < 8) {
    return false;
  }

  const hasLetter = /[a-zA-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  const criteriaMet = [hasLetter, hasNumber, hasSpecialChar].filter(Boolean).length;

  return criteriaMet >= 2;
}
