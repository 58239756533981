import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/sign/step-one.png'
import _imports_1 from '@/assets/images/sign/step-two.png'


const _hoisted_1 = { class: "register-wrap" }
const _hoisted_2 = { class: "input-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "error-message-email"
}
const _hoisted_4 = { class: "bottom-wrap" }
const _hoisted_5 = { class: "login_button-text" }

import { computed, ref } from "vue";
import { firebaseService } from "@/utils/firebaseService";
import { canTriggerEmail, recordEmailTrigger, isValidEmail } from "@/utils/index";
import loginStartImage from "@/assets/images/login_image/login_start.png";
import loginEndImage from "@/assets/images/login_image/login_end.png";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterComponent',
  setup(__props) {

const email = ref<string>("");
const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const errorMessage = ref<string>("");
const resendTimer = ref<number>(0);
const isEmail = computed(() => isValidEmail(email.value));
let timer: any = null;

// 实时获取email
const updateEmail = (event: Event) => {
  const target = event.target as HTMLInputElement;
  email.value = target.value;
};

// 显示用户弹窗
const showModal = () => {
  open.value = true;
};

const isEmailValid = computed(() => isValidEmail(email.value));
const canSendEmail = computed(() => isEmailValid.value && resendTimer.value <= 0);

// 对email进行检查
const checkEmail = () => {
  if (!isEmailValid.value) {
    errorMessage.value = "Invalid email.";
  } else {
    errorMessage.value = "";
  }
};
const registerButtonImage = computed(() => (isEmailValid.value ? loginEndImage : loginStartImage));
// 给邮箱发送邮件
const sendEmail = async () => {
  console.log("@@@start registerAndSendVerification: ", email);
  const result = await firebaseService.registerAndSendVerification(email.value);
  console.log("@@@end registerAndSendVerification: ", result);
};

const sendText = computed(() => (resendTimer.value ? `RESEND AFTER ${resendTimer.value}S` : "SEND EMAIL"));

// 记录发送时间
const startResendTimer = () => {
  resendTimer.value = 60; // 设定60秒
  timer = setInterval(() => {
    if (resendTimer.value > 0) {
      resendTimer.value -= 1;
    } else {
      clearInterval(timer);
      timer = null;
    }
  }, 1000);
};

const handleSendEmail = async () => {
  if (canSendEmail.value) {
    const { canTrigger, remainingTime } = canTriggerEmail();
    if (canTrigger) {
      recordEmailTrigger();
      showModal();
      await sendEmail();
      errorMessage.value = "";
      startResendTimer();
    } else {
      errorMessage.value = `Failed to send verification email. Please try again after ${remainingTime} seconds.`;
      resendTimer.value = remainingTime;
    }
  }
};

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<span class=\"sign_form-title\" data-v-58f9344b>SIGN IN</span><br data-v-58f9344b><div class=\"step-indicator\" data-v-58f9344b><div class=\"step-one\" data-v-58f9344b><img src=\"" + _imports_0 + "\" alt=\"\" data-v-58f9344b><span class=\"step_one-num\" data-v-58f9344b>1</span></div><hr class=\"line\" data-v-58f9344b><div class=\"step-two\" data-v-58f9344b><img src=\"" + _imports_1 + "\" alt=\"\" data-v-58f9344b><span class=\"step_two-num\" data-v-58f9344b>2</span></div></div><p class=\"step-description\" data-v-58f9344b>Step 1: Enter your email</p>", 4)),
      _createVNode(_component_a_input, {
        value: email.value,
        onInput: updateEmail,
        onBlur: checkEmail,
        placeholder: "Email",
        class: "account"
      }, null, 8, ["value"]),
      (errorMessage.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(errorMessage.value), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["btn-wrap", {
        btn_start: !canSendEmail.value,
        btn_end: canSendEmail.value,
      }]),
        onClick: handleSendEmail
      }, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(sendText.value), 1)
      ], 2),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "sign_footer" }, [
        _createElementVNode("span", { class: "account-prompt" }, "Already have an account?"),
        _createElementVNode("a", {
          class: "login_login-in",
          href: "/login",
          target: "_blank",
          rel: "noopener noreferrer"
        }, " Login here")
      ], -1))
    ]),
    _createVNode(_component_a_modal, {
      open: open.value,
      "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((open).value = $event)),
      title: "",
      centered: "",
      onOk: handleOk
    }, {
      footer: _withCtx(() => _cache[3] || (_cache[3] = [])),
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "verification-container" }, [
          _createElementVNode("h1", { class: "verification-title" }, "Email verification"),
          _createElementVNode("p", { class: "verification-message" }, " An email has been sent to your inbox. Please check your email to verify. ")
        ], -1))
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}
}

})