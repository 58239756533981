import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrap" }

import CenterWrap from './CenterWrap.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginCommonLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CenterWrap)
  ]))
}
}

})