<template>
  <div class="app-top-nav">
    <div class="w">
      <ul>
        <li v-for="(item, index) in navItems" :key="index">
          <Router-Link :to="item.path" :class="{ active: currentRoute === item.path }">
            {{ item.name }}
          </Router-Link>
        </li>
        <li>
          <a href="mailto:support@planetlinkinc.com?subject=Customer%20Service"
            >Contact</a
          >
        </li>
      </ul>
      <a
        @click="goToLoginPage"
        @keydown.enter="goToLoginPage"
        @keydown.space.prevent="goToLoginPage"
        tabindex="0"
        class="interactive-link"
      >
        <img
          v-if="!isLoggedIn"
          src="@/assets/images/login_image.png"
          alt=""
          class="login"
        />
        <span v-if="!isLoggedIn" class="login_text">Login</span>
      </a>
      <a-popover>
        <template #content>
          <div class="popover-content">
            <p><strong>Account:</strong> {{ userEmail }}</p>
            <p>
              <strong>Connection mode:</strong> <span class="vip-badge">VIP</span> Yearly
              Plan
            </p>
            <p><strong>Devices:</strong> 1/1 Devices</p>
            <p><strong>Valid until:</strong> XX,XX,XXXX</p>
            <button @click="signOut" class="sign-out-button">SIGN OUT</button>
          </div>
        </template>
        <a class="interactive-link">
          <span v-if="isLoggedIn" class="avatar-circle">{{ userInitial }}</span>
        </a>
      </a-popover>
      <div class="download">
        <img src="@/assets/images/download_image.png" alt="" />
        <a
          href="https://play.google.com/store/apps/details?id=com.freeguardvpn.app"
          class="text_6"
          >Downloads</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { firebaseService, getUserEmail } from "@/utils/firebaseService";

// 获取当前路由
const router = useRouter();
const route = useRoute();
const store = useStore();

// 定义响应式变量
// const isLoggedIn = computed(() => store.state.user.isLoggedIn);
// const userEmail = computed(() => getUserEmail());
const userEmail = ref("");
const isLoggedIn = computed(() => !!userEmail.value);
const currentRoute = computed(() => route.path);
const userInitial = computed(() => {
  const { username } = store.state.user;
  console.log("Username:", username);
  return username ? username.charAt(0).toUpperCase() : "";
});

function goToLoginPage() {
  router.push("/account/login");
}
// 导航项
const navItems = [
  { name: "Home", path: "/" },
  { name: "Terms of service", path: "/terms" },
  { name: "Privacy policy", path: "/privacy" },
  { name: "IOS Privacy Policy", path: "/iosprivacy" },
];

const updateUserEmail = () => {
  userEmail.value = getUserEmail();
};

const signOut = async () => {
  const result = await firebaseService.signOut();
  if (result.success) {
    updateUserEmail();
  }
};

onMounted(() => {
  updateUserEmail();
});
</script>

<style lang="less">
.app-top-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  background-color: rgb(24, 21, 48);
  z-index: 3;
  line-height: 80px;

  ul {
    display: flex;

    li {
      position: relative;
      left: -8%;
      width: auto;

      a:first-child {
        padding: 0 30px;
      }

      a {
        font-family: PingFang SC;
        padding: 0 30px;
        color: #d7d6e3;
        font-weight: 500;
        font-size: 16px;
        transition: color 0.3s;

        &:hover,
        &.active {
          background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.w {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
}

.download {
  position: relative;
  left: 2%;
  align-items: center;
  display: flex;
}

.download img {
  width: 125px;
  height: 38px;
}

.text_6 {
  position: absolute;
  text-align: center;
  line-height: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: PingFang SC;
  font-size: 16px;
}

.login {
  position: relative;
  left: 38px;
}

.login_text {
  position: absolute;
  left: 78%;
  transform: translateX(-50%);
  font-size: 18px;
  background: repeating-linear-gradient(to right, #237fff, #75d5ff, #ed5aff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.interactive-link {
  cursor: pointer;
}

.account_info {
  background-color: rgb(56, 48, 98);
}

.popover-content {
  background-color: rgb(56, 48, 98);
  color: rgb(168, 174, 244);
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ant-popover-content,
.ant-popover-inner,
.ant-popover-inner-content,
.popover-content {
  background-color: rgb(56, 48, 98) !important;
  border-radius: 50px !important;
}

.popover-content p {
  margin: 10px 0;
  font-size: 14px;
}

.vip-badge {
  background-color: #fbc02d;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  font-weight: bold;
}

.sign-out-button {
  margin-top: 20px;
  margin-left: 35px;
  padding: 10px 50px;
  background-color: #8e99f3;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sign-out-button:hover {
  background-color: #5c6bc0;
}

.avatar-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  color: white;
  font-size: 20px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
</style>
