<template>
  <div>
    <component :is="currentHomeComponent" />
  </div>
</template>

<script>
import {
  ref,
} from 'vue';
import HomePc from '@/views/pc/Home_pc.vue';
import HomeMobile from '@/views/mobile/Home_mobile.vue';

window.addEventListener('resize', () => {
  console.log(`Window resized to: ${window.innerWidth}x${window.innerHeight}`);
  console.log(window.innerWidth);
});

export default {
  setup() {
    const detectDevice = () => {
      const isMobile = window.innerWidth <= 748;
      return isMobile ? HomeMobile : HomePc;
    };
    const currentHomeComponent = ref(detectDevice());

    return {
      currentHomeComponent,
    };
  },
};
</script>
