import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/sign/step-one.png'
import _imports_1 from '@/assets/images/sign/step-two-bright.png'
import _imports_2 from '@/assets/images/login_image/login_start.png'


const _hoisted_1 = { class: "setPassword_wrap" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = {
  key: 1,
  class: "password-hint"
}
const _hoisted_5 = { class: "email-send-button" }

import { ref } from "vue";
import { isPasswordStrong } from "@/utils/index";
import { Modal } from "ant-design-vue";
import { firebaseService, getUserEmail } from "@/utils/firebaseService";
import { useRouter } from "vue-router";
import { openErrModal } from "@/utils/ui";


export default /*@__PURE__*/_defineComponent({
  __name: 'SetPassWord',
  setup(__props) {

const router = useRouter();

const loading = ref<boolean>(false);
const open = ref<boolean>(false);
const errorMessage = ref<string>("");
const password = ref("");
const confirmPassword = ref("");
const passwordError = ref("");

const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    open.value = false;
  }, 2000);
};

const handleCancel = () => {
  open.value = false;
};

const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Password must be at least 8 characters long and contain two of the following: letters, numbers, special characters.";
  } else if (password.value !== confirmPassword.value) {
    passwordError.value = "Passwords do not match.";
  } else {
    passwordError.value = "";
  }
};

const confirmLogin = async () => {
  const userEmail = getUserEmail();
  if (!userEmail) {
    openErrModal("email invalid");
  }
  console.log("@@@start setPassword");
  const result = await firebaseService.setPassword(confirmPassword.value);
  console.log("@@@end setPassword", result);
  if (result.success) {
    // 跳转到首页
    router.push({ path: "/" });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createStaticVNode("<span class=\"form-title\" data-v-4598c822>SIGN IN</span><br data-v-4598c822><div class=\"step-indicator\" data-v-4598c822><div class=\"step-one\" data-v-4598c822><img src=\"" + _imports_0 + "\" alt=\"\" data-v-4598c822><span class=\"step-number\" data-v-4598c822>1</span></div><hr class=\"step-line\" data-v-4598c822><div class=\"step-two\" data-v-4598c822><img src=\"" + _imports_1 + "\" alt=\"\" data-v-4598c822><span class=\"step-number\" data-v-4598c822>2</span></div></div><p class=\"step-description\" data-v-4598c822>Step 2 : Set password</p>", 4)),
      (passwordError.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(passwordError.value), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, "Any character with 6 or more digits")),
      _createVNode(_component_a_space, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_input_password, {
            value: password.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event)),
            placeholder: "Password",
            onFocusout: checkPassword,
            class: "password-input"
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_a_space, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_input_password, {
            value: confirmPassword.value,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((confirmPassword).value = $event)),
            placeholder: "Confirm Password",
            onFocusout: checkPassword,
            class: "password-confirm-input"
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_a_button, {
        type: "primary",
        class: "confirmation-button",
        onClick: confirmLogin
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("img", {
            src: _imports_2,
            alt: "",
            class: "confirmation-image"
          }, null, -1),
          _createElementVNode("span", { class: "button-text" }, "confirm & login", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_a_modal, {
        open: open.value,
        "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((open).value = $event)),
        title: "",
        onOk: handleOk
      }, {
        footer: _withCtx(() => _cache[7] || (_cache[7] = [])),
        default: _withCtx(() => [
          _createElementVNode("div", { class: "verification-section" }, [
            _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "verification-title" }, "Email verification", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "verification-message" }, " An email has been sent to your inbox. Please check your email to verify. ", -1)),
            _createElementVNode("button", {
              key: "back",
              onClick: handleCancel,
              class: "verification-action-button"
            }, " GO TO MAIL ")
          ])
        ]),
        _: 1
      }, 8, ["open"])
    ])
  ]))
}
}

})