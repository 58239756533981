import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "center-wrap" }
const _hoisted_3 = { class: "func-wrap" }


export default /*@__PURE__*/_defineComponent({
  __name: 'CenterWrap',
  setup(__props) {

// import LoginComponent from '@/views/login/LoginComponent.vue';
// import RegisterComponent from '@/views/login/RegisterComponent.vue';
// import SetPassWord from '../SetPassWord.vue';

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "info-wrap" }, [
        _createElementVNode("div", { class: "title" }, "FREEGUARD VPN"),
        _createElementVNode("div", { class: "content" }, " Unlimited fast & secure proxy Protect your online privacy with fast and secure free VPN proxy ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}
}

})