import { createStore } from 'vuex';
import user from './modules/user';

export default createStore({
  state: {
    user: {
      isLoggedIn: false,
      username: '',
      email: '',
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user.isLoggedIn = true;
      state.user.username = payload.username;
      state.user.email = payload.email;
    },
  },
  actions: {
    login({ commit }, username) {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          commit('setUser', username);
          resolve();
        }, 1000);
      });
    },
  },
  getters: {
    isLoggedIn: (state) => state.user.isLoggedIn,
    username: (state) => state.user.username,
  },
});
