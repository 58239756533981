<template>
  <!-- <AppTopNavMobile /> -->
  <div class="appMobile">
    <div class="app-header-nav" id="part_1">
      <img src="@/assets/images/star.jpg" alt="">
      <div class="content_1" id="part_2">
        <span class="wrapper text_1">FREEGUARD VPN</span>
        <span class="wrapper text_2">Unlimited Fast & Secure Proxy</span>
        <span class="wrapper text_3">
          Protect your online privacy with fast <br>and secure free VPN proxy</span>
      </div>
    </div>
    <!-- 可拉伸背景图 -->
    <div class="background_1">
      <div class="part_start">
        <div class="content_2">
          <span class="wrapper text_6" id="part_3">FEATURES INTRO</span>
          <span class="wrapper text_7">Protect privacy</span>
          <span class="wrapper text_8">
            Help you protect your online privacy,
            especially when connecting to public Wi-Fi networks.</span>
          <span class="wrapper text_9">Bypass geographic restrictions</span>
          <span class="wrapper text_10">Allow you to bypass geographic restrictions and
            access content and websites that may be blocked in your respective regions.</span>
          <span class="wrapper text_11">Improve network speed</span>
          <span class="wrapper text_12">Some of you may need improve network speeds,
            especially when your internet connections are slow.
            That’s exactly what FreeGuard VPN is capable of.</span>
        </div>
        <img src="@/assets/images/image1.png" alt="" class="image_1">
      </div>
      <div class="part_middle">
        <div class="content_3">
          <span class="wrapper text_13">Improve online security</span>
          <span class="wrapper text_14">Help you protect your online privacy,
            especially when connecting to public Wi-Fi networks.</span>
          <span class="wrapper text_15">Download and share files</span>
          <span class="wrapper text_16">Can allow you to download and share
            files anonymously,
            especially when using P2P sharing.</span>
          <span class="wrapper text_17">Access international services</span>
          <span class="wrapper text_18">
            Can enable you to access international streaming services and websites,
            such as Netflix and Hulu.</span>
          <span class="wrapper text_19" id="part_2">Bypass website censorship</span>
          <span class="wrapper text_20" id="part_3">Can help you from countries
            bypass website censorship.</span>
        </div>
        <img src="@/assets/images/image2.png" alt="" class="image_2">
      </div>
      <div class="part_end">
        <div class="background_2">
          <span class="text_21" id="part_4">USER COMMENTS</span>
          <div class="image3">
            <div class="circle">
              <div class="circle_1">
                <h3>C</h3>
              </div>
            </div>
            <div class="rectangle_1">
              <p>Chloe ***</p>
              <div class="text_22">This app is pretty useful and stable.
                I would like to recommend it to my family and friends.
                Please keep going to improve it!</div>
              <img src="@/assets/images/star_1.png" alt="" class="star_1">
            </div>
          </div>
          <div class="image4">
            <div class="circle">
              <div class="circle_2">
                <h3>M</h3>
              </div>
            </div>
            <div class="rectangle_2">
              <p>Mohammad ***</p>
              <div class="text_22">Best apps. Very easy to connect.</div>
              <img src="@/assets/images/star_1.png" alt="" class="star_1">
            </div>
          </div>
          <div class="image5">
            <div class="circle">
              <div class="circle_3">
                <h3>G</h3>
              </div>
            </div>
            <div class="rectangle_3">
              <p>Gregory ***</p>
              <div class="text_22">
                It works I can for sure tell you that much it does get the job done</div>
              <img src="@/assets/images/star_1.png" alt="" class="star_1">
            </div>
          </div>
          <img src="@/assets/images/bg2.png" alt="" class="bg2">
        </div>
        <!-- 吸底下载键 -->
        <div class="image_bottom">
          <a href="https://play.google.com/store/apps/details?id=com.freeguardvpn.app">
            <div class="download">
              <img src="@/assets/images/download_icon.png" alt="" class="download_icon">
              <span class="text_23">Downloads</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/style/base_mobile.less';

export default {
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY <= 0) {
        window.scrollTo(0, 0); // 添加分号
      }
    },
  },
};
</script>

<style lang="less" scoped>
.appMobile {
  width: 100%;
  margin-top: 0;
}

img {
  width: 100%;
  object-fit: contain;
}

.app-header-nav {
  position: relative;
  display: flex;
}

.tab {
  position: absolute;
  float: left;
  margin: 155px 0 0 20vw;
  z-index: 2;
}

.tab a {
  outline: none;
  text-decoration: none;
  display: inline-block; // 设置成为行内块元素
  color: rgba(255, 255, 255, 0);
}

.tab a:hover {
  background-color: #6771FF;
}

.content_1 {
  position: absolute;
  height: 450px;
  float: left;
  top: 20%;
  left: 5%;
  z-index: 1;
}

.wrapper {
  // 宽度自适应文字
  text-align: left;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  /* 换行 */
  justify-content: flex-start;
  /* 左对齐*/
}

.text_1 {
  font-size: 4vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #237FFF, #75D5FF, #ED5AFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_2 {
  margin: 10px 0;
  font-size: 4vw;
  font-weight: 600;
  color: #fff;
}

.text_3 {
  margin: 40px 0;
  font-size: 3vw;
  color: #fff;
}

.part_start,
.part_middle {
  // padding: 0 5vw;
}

.part_end {
  height: 165vw;
  // padding: 0 vw;
}

// 可拉伸背景图
.background_1 {
  position: relative;
  background-image: url('@/assets/images/bg.jpg');
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  /* 控制背景图片的位置，与上方盒子无缝拼接 */
  width: 100%;
  height: 100%;
  /* 设置 div 的高度为整个视口高度 */
}

.image_1 {
  object-fit: contain;
  width: 100vw;
  // height: 722px;
}

.content_2 {
  padding: 0 5vw;
}

.text_6 {
  font-size: 4vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #FAD62C, #E664FF, #4FAEFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_7 {
  margin: 70px 0 0;
  font-size: 3vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_8 {
  margin: 10px 0 0;
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}

.text_9 {
  margin: 40px 0 0;
  font-size: 3vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_10 {
  margin: 10px 0 0;
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}

.text_11 {
  margin: 40px 0 0;
  font-size: 3vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_12 {
  margin: 10px 0 0;
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}

// iphone_2
.image_2 {
  width: 100vw;
}

.content_3 {
  padding: 0 5vw;
}

.text_13 {
  font-size: 3vw;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_14 {
  margin: 10px 0 0;
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}

.text_15 {
  font-size: 3vw;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_16 {
  margin: 10px 0 0;
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}

.text_17 {
  font-size: 3vw;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_18 {
  margin: 10px 0 0;
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}

.text_19 {
  font-size: 3vw;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_20 {
  margin: 10px 0 0;
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}

// 评论区
.background_2 {
  // position: relative;
  // top: 30%;
  width: 100vw;
  height: 135vw;
}

// 评论背景图
.bg2 {
  // height: 1000px;
}

.rectangle_1 {
  position: relative;
  border-radius: 5vw;
  width: 90vw;
  height: 30vw;
  // height: 200px;
  background-color: #fff;
}

.rectangle_2 {
  position: relative;
  // border-radius: 20px 50px 50px 50px;
  border-radius: 5vw;
  width: 90vw;
  // height: 200px;
  height: 30vw;
  background-color: #fff;
}

.rectangle_3 {
  position: relative;
  // border-radius: 20px 50px 50px 50px;
  border-radius: 5vw;
  width: 90vw;
  // height: 200px;
  height: 30vw;
  background-color: #fff;
}

.circle {
  position: absolute;
  top: -15%;
  border-radius: 150px;
  width: 17vw;
  height: 17vw;
  background-color: #fff;
  z-index: 1;
}

.circle_1 {
  position: absolute;
  top: 8%;
  left: 9%;
  border-radius: 100px;
  width: 14vw;
  height: 14vw;
  background-color: rgb(214, 138, 52);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.circle_2 {
  position: relative;
  position: absolute;
  top: 8%;
  left: 9%;
  border-radius: 100px;
  width: 14vw;
  height: 14vw;
  background-color: rgb(98, 198, 133);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.circle_3 {
  position: absolute;
  top: 8%;
  left: 9%;
  border-radius: 100px;
  width: 14vw;
  height: 14vw;
  background-color: rgb(226, 105, 137);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

h3 {
  margin: 0;
  font-size: 10vw;
  color: #fff;
}

p {
  // padding-top: 20px;
  padding-top: 3vw;
  padding-left: 18vw;
  font-size: 4vw;
  font-weight: bold;
}

.star_1 {
  position: absolute;
  top: 10%;
  left: 60%;
  // width: 214px;
  width: 30vw;
  // height: 33px;
}

.image3 {
  position: absolute;
  // height: 350px;
  margin: 20vw 0 0 0;
  left: 5%;
}

.image4 {
  position: absolute;
  // height: 350px;
  margin: 58vw 0 0 0;
  left: 5vw;
}

.image5 {
  position: absolute;
  // height: 400px;
  margin: 96vw 0 0 0;
  left: 5vw;
}

.text_21 {
  position: absolute;
  // top: 5%;
  // left: 5vw;
  padding: 6vw 5vw;
  color: #fff;
  font-size: 4vw;
  font-weight: 600;
  z-index: 2;
}

.text_22 {
  // padding: 40px 60px 0 60px;
  padding: 5vw 7vw 0 7vw;
  font-size: 3.5vw;
  z-index: 3;
}

.image_bottom {
  background-image: url('@/assets/images/image_bottom.png');
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  z-index: 2;
}

.download {
  position: relative;
  background-image: url('@/assets/images/download_image3.png');
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  /* 控制背景图片的位置，与上方盒子无缝拼接 */
  // width: 707px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 20vw;
  left: 50%;
  transform: translate(-50%);
  bottom: 10px;
  z-index: 3;
}

.download_icon {
  position: absolute;
  left: 35%;
  top: 50%;
  transform: translateY(-50%);
  width: 6vw;
  // height: 45px;
  z-index: 2;
}

.text_23 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 45%;
  font-size: 4vw;
  color: #fff;
}
</style>
