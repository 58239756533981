<template>
  <div class="app-wrap" id="app">
    <!-- transition组件包裹router-view -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
.app-wrap {
  width: 100%;
  height: 100%;
}
</style>
