<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div>
    <div class="login_form">
      <span class="login_form-title">LOGIN</span>
      <!-- 用户名 -->
      <a-input v-model:value="email" placeholder="Account" class="account" />
      <!-- 密码框 -->
      <a-input-password
        v-model:value="password"
        @focusout="checkPassword"
        placeholder="Password"
        class="password"
      />
      <p v-if="passwordError" class="err-msg">{{ passwordError }}</p>
      <div
        class="login_wrap"
        :class="{
          login_button_start: !canLogin,
          login_button_end: canLogin,
        }"
        @click="navigateToHome"
      >
        <span class="login_button-text">LOGIN</span>
      </div>
      <div class="login_footer">
        <span class="login_forgot-password">Forgot password.</span>
        <a
          class="login_sign-in"
          href="/register"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sign in here
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { isPasswordStrong } from "@/utils/index";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { firebaseService } from "@/utils/firebaseService";

const password = ref("");
const passwordError = ref("");
const router = useRouter();
const store = useStore();
const email = ref<string>("");

const canLogin = computed(() => email.value && password.value && !passwordError.value);

// 实时获取密码
watch(password, (newPassword) => {
  console.log("Checking password:", newPassword);
  if (!isPasswordStrong(newPassword)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
});

// 检查密码强度
const checkPassword = () => {
  if (!isPasswordStrong(password.value)) {
    passwordError.value = "Password invalid.";
  } else {
    passwordError.value = "";
  }
};

// 登录成功跳转home页
const navigateToHome = async () => {
  if (!passwordError.value && email.value && password.value) {
    // console.log('Username:', name.value);
    const result = await firebaseService.login(email.value, password.value);
    if (result.success) {
      store.commit("setUser", { username: email.value, email: email.value });
      router.push("/");
    }
  }
};
</script>
<style lang="less" scoped>
.login_form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .err-msg {
    color: red;
    font-size: 1vw !important;
    // margin-top: 1vw !important;
    margin-left: 1vw !important;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    word-break: break-word;
    width: 23vw;
  }

  .login_form-title {
    display: flex;
    justify-content: center;
    font-family: Helvetica, Helvetica;
    font-weight: bold;
    font-size: 2vw;
    line-height: 4vh;
    color: #180b38;
    text-align: left;
    font-style: normal;
    margin-bottom: 3.8vh;
  }

  .account,
  .password {
    // margin-top: 2vw !important;
    font-size: 1vw !important;
    margin: 1vw 0;
    width: 25vw;
    height: 8vh;
    border-radius: 5vw;
    font-weight: bolder;
    padding: 0 1vw;
    background-color: #e5e7f4;
    display: flex;
    align-items: center;
  }

  .password {
    input {
      border-radius: 5vw;
      height: 7vh;
      background-color: transparent !important;
    }
  }

  ::v-deep(.css-dev-only-do-not-override-1p3hq3p).ant-input-affix-wrapper
    > input.ant-input {
    background-color: #e5e7f4 !important;
    font-weight: bold;
  }

  .login_button-text {
    color: rgb(230, 219, 253);
    font-size: 1.5vw;
    font-weight: bold;
  }

  .login_wrap {
    margin-top: 2vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vw;
    height: 8vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    // background-size: contain;
  }

  .login_button_start {
    background-image: url("@/assets/images/login_image/login_start.png");
    // background-size: 100% auto;
  }

  .login_button_end {
    background-image: url("@/assets/images/login_image/login_end.png");
    // background-size: 100% auto;
  }

  .login_footer {
    margin-top: 1vw;

    .login_forgot-password {
      margin-top: 3vw;
      // margin-left: -3vw;
      color: #180b38;
    }

    .login_sign-in {
      margin-top: 3vw;
      margin-left: 13vw;
      color: #7555f2;
      text-decoration: underline;
    }
  }
}
</style>
