import { createApp } from 'vue';
import { VueShowdown } from 'vue-showdown';
import Antd from 'ant-design-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/style/markdown.less';
import 'ant-design-vue/dist/reset.css';

console.log(`App version: ${process.env.VUE_APP_VERSION}`);
console.log('111');
createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .component('VueShowdown', VueShowdown as any)
  .mount('#app');
// app.component('VueShowdownPlugin', VueShowdownPlugin);
