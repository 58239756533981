import { initializeApp } from 'firebase/app';
import {
  getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, updatePassword,
  sendSignInLinkToEmail,
  applyActionCode,
  signInWithEmailLink,
  setPersistence,
  browserLocalPersistence,
  signOut,
} from 'firebase/auth';
import { openErrModal } from './ui';

// 初始化 Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDhFByKyc9aSF7Dj0vPANkXoXVXE2C05Lc",
  authDomain: "freeguard-14d08.firebaseapp.com",
  databaseURL: "https://freeguard-14d08-default-rtdb.firebaseio.com",
  projectId: "freeguard-14d08",
  storageBucket: "freeguard-14d08.firebasestorage.app",
  messagingSenderId: "845024997837",
  appId: "1:845024997837:web:ee8d43f9c8279553ff5484",
  measurementId: "G-VFNT9EWCK0",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const emailItemKey = "emailForSignIn";

export function getUserEmail() {
  return window.localStorage.getItem(emailItemKey) || auth.currentUser?.email || "";
}

export function removeUserEmail() {
  window.localStorage.removeItem(emailItemKey);
}

export function setUserEmail(email: string) {
  window.localStorage.setItem(emailItemKey, email);
}

export const getRedirectUrl = () => {
  const url = window.location.href;
  const urlObj = new URL(url);
  return `${urlObj.origin}/account/password`;
};

export const firebaseService = {
  // 注册并发送验证邮件
  async registerAndSendVerification(email: string) {
    const actionCodeSettings = {
      url: getRedirectUrl(),
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      setUserEmail(email);
      console.log('Verification email sent.');
    } catch (error: any) {
      openErrModal(error.message);
      console.error('Error sending email verification:', error);
      throw error;
    }
  },

  async handleEmailVerification(oobCode: string) {
    try {
      // // 检查操作代码的有效性
      // await checkActionCode(auth, oobCode);
      // 应用操作代码
      await applyActionCode(auth, oobCode);
      return { success: true, message: 'Email verified. You can now set your password.' };
    } catch (error: any) {
      openErrModal(error.message);
      return { success: false, message: error.message };
    }
  },

  async setPassword(password: string) {
    const email = getUserEmail();

    if (!email) {
      return { success: false, message: 'Email invalid.' };
    }

    try {
      const result = await signInWithEmailLink(auth, email, window.location.href);
      const { user } = result;

      // 设置新密码
      await updatePassword(user, password);
      return { success: true, message: 'Password set successfully.' };
    } catch (error: any) {
      openErrModal(error.message);
      return { success: false, message: error.message };
    }
  },

  // 登录
  async login(email: string, password: string) {
    try {
      // 先设置持久性
      await setPersistence(auth, browserLocalPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      setUserEmail(email);
      return { success: true, message: 'Login successful.' };
    } catch (error: any) {
      openErrModal(error.message);
      return { success: false, message: error.message };
    }
  },

  // 发送重置密码邮件
  async resetPassword() {
    try {
      const email = getUserEmail();
      await sendPasswordResetEmail(auth, email);
      return { success: true, message: 'Password reset email sent.' };
    } catch (error: any) {
      openErrModal(error.message);
      return { success: false, message: error.message };
    }
  },

  async signOut() {
    try {
      await signOut(auth);
      removeUserEmail();
      console.log('Sign out successful.');
      return { success: true, message: 'Sign out successful.' };
    } catch (error: any) {
      console.error('Error signing out:', error);
      openErrModal(error.message);
      return { success: false, message: error.message };
    }
  },
};
