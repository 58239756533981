import Terms from '@/views/category/TermsOfService.vue';
import { RouteRecordRaw } from 'vue-router';
import LoginComponent from '@/views/login/LoginComponent.vue';
import RegisterComponent from '@/views/login/RegisterComponent.vue';
import SetPassWord from '@/views/login/SetPassWord.vue';
import Layout from '../views/MainLayout.vue';
import Home from '../views/HomeView.vue';
import Con from '../views/category/ContactUs.vue';
import PrivacyPolicy from '../views/category/PrivacyPolicy.vue';
import IOSPrivacyPolicy from '../views/category/IOSPrivacyPolicy.vue';
import LoginLayout from '../views/login/common/LoginCommonLayout.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        component: Home, // 动态选择组件
        meta: { transition: '' },
      },
      {
        path: '/terms',
        component: Terms,
        meta: { transition: '' },
      },
      {
        path: '/con',
        component: Con,
      },
      {
        path: '/privacy',
        component: PrivacyPolicy,
        meta: { transition: '' },
      },
      {
        path: '/iosprivacy',
        component: IOSPrivacyPolicy,
        meta: { transition: '' },
      },
      {
        path: 'account',
        component: LoginLayout,
        meta: { requiresNavBar: false },
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'register',
            component: RegisterComponent,
          },
          {
            path: 'password',
            component: SetPassWord,
          },
        ],
      },
    ],
  },
];
