import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/images/login_image.png'
import _imports_1 from '@/assets/images/download_image.png'


const _hoisted_1 = { class: "app-top-nav" }
const _hoisted_2 = { class: "w" }
const _hoisted_3 = ["onKeydown"]
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "login"
}
const _hoisted_5 = {
  key: 1,
  class: "login_text"
}
const _hoisted_6 = { class: "popover-content" }
const _hoisted_7 = { class: "interactive-link" }
const _hoisted_8 = {
  key: 0,
  class: "avatar-circle"
}

import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { firebaseService, getUserEmail } from "@/utils/firebaseService";

// 获取当前路由

export default /*@__PURE__*/_defineComponent({
  __name: 'AppTopNav',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const store = useStore();

// 定义响应式变量
// const isLoggedIn = computed(() => store.state.user.isLoggedIn);
// const userEmail = computed(() => getUserEmail());
const userEmail = ref("");
const isLoggedIn = computed(() => !!userEmail.value);
const currentRoute = computed(() => route.path);
const userInitial = computed(() => {
  const { username } = store.state.user;
  console.log("Username:", username);
  return username ? username.charAt(0).toUpperCase() : "";
});

function goToLoginPage() {
  router.push("/account/login");
}
// 导航项
const navItems = [
  { name: "Home", path: "/" },
  { name: "Terms of service", path: "/terms" },
  { name: "Privacy policy", path: "/privacy" },
  { name: "IOS Privacy Policy", path: "/iosprivacy" },
];

const updateUserEmail = () => {
  userEmail.value = getUserEmail();
};

const signOut = async () => {
  const result = await firebaseService.signOut();
  if (result.success) {
    updateUserEmail();
  }
};

onMounted(() => {
  updateUserEmail();
});

return (_ctx: any,_cache: any) => {
  const _component_Router_Link = _resolveComponent("Router-Link")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navItems, (item, index) => {
          return _createElementVNode("li", { key: index }, [
            _createVNode(_component_Router_Link, {
              to: item.path,
              class: _normalizeClass({ active: currentRoute.value === item.path })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"])
          ])
        }), 64)),
        _cache[0] || (_cache[0] = _createElementVNode("li", null, [
          _createElementVNode("a", { href: "mailto:support@planetlinkinc.com?subject=Customer%20Service" }, "Contact")
        ], -1))
      ]),
      _createElementVNode("a", {
        onClick: goToLoginPage,
        onKeydown: [
          _withKeys(goToLoginPage, ["enter"]),
          _withKeys(_withModifiers(goToLoginPage, ["prevent"]), ["space"])
        ],
        tabindex: "0",
        class: "interactive-link"
      }, [
        (!isLoggedIn.value)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true),
        (!isLoggedIn.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Login"))
          : _createCommentVNode("", true)
      ], 40, _hoisted_3),
      _createVNode(_component_a_popover, null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", null, [
              _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Account:", -1)),
              _createTextVNode(" " + _toDisplayString(userEmail.value), 1)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Connection mode:"),
              _createTextVNode(),
              _createElementVNode("span", { class: "vip-badge" }, "VIP"),
              _createTextVNode(" Yearly Plan ")
            ], -1)),
            _cache[3] || (_cache[3] = _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Devices:"),
              _createTextVNode(" 1/1 Devices")
            ], -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Valid until:"),
              _createTextVNode(" XX,XX,XXXX")
            ], -1)),
            _createElementVNode("button", {
              onClick: signOut,
              class: "sign-out-button"
            }, "SIGN OUT")
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("a", _hoisted_7, [
            (isLoggedIn.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(userInitial.value), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "download" }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }),
        _createElementVNode("a", {
          href: "https://play.google.com/store/apps/details?id=com.freeguardvpn.app",
          class: "text_6"
        }, "Downloads")
      ], -1))
    ])
  ]))
}
}

})